body {
  left: 0;
  top: 0;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: "Saira Semi Condensed", sans-serif;
}
.buttonThemeHange {
  position: fixed !important;
  bottom: 55px;
  z-index: 9999;
  left: 6px;
  // background-color: #e9e9e9  !important;
}
$blue: #0d8ccd;
$black: #000;
$small-font: 16px;
.buttonThemeHange11 {
  display: flex;
  justify-content: space-between;
}
.heading1 {
  color: $blue;
  font-size: $small-font;
  background-color: $black;
  display: flex;
  justify-content: center;
}
.textbox {
  h1 {
    color: red;
    &:hover {
      color: black;
    }
  }
}

.d-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  &.justify-space-between {
    justify-content: space-between;
  }
  &.justify-start {
    justify-content: flex-start;
  }
  &.alignstart {
    align-items: flex-start !important;
  }
  &.justify-end {
    justify-content: flex-end;
  }
}
.height100 {
  height: 100%;
}
.padding10 {
  padding: 10px 0;
}
.extra-bold {
  font-weight: 700 !important;
}
.coin_list {
  .MuiTypography-body2 span {
    font-size: 17px;
    font-weight: 600;
  }
  .MuiTypography-body1 span {
    font-size: 13px;
    font-weight: 300;
  }
}
.d-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  &.justify-space-between {
    justify-content: space-between;
  }
  &.justify-start {
    justify-content: flex-start;
  }
  &.alignstart {
    align-items: flex-start !important;
  }
  &.justify-end {
    justify-content: flex-end;
  }
}
.step_bar {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  box-shadow: 0 0.1rem 0.7rem rgba(0, 0, 0, 0.1);
}
.completed,
.activeStep {
  h5 {
    text-align: center;

    // left: 0px;
    font-size: 15px;
    // display: flex;
    justify-content: center;
    align-items: center;
    color: #31335d;
  }
}
.completed {
  height: 50px;
  width: 50px;
  border: 3px solid #5a86ff;
  border-radius: 50%;
  margin: 0 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: #fff;
}
.centero {
  margin: auto;
  max-width: 500px;
}
.activeStep {
  height: 50px;
  width: 50px;
  border: 3px solid #dfdfdf;
  border-radius: 50%;
  margin: 0 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: #fff;
  z-index: 999;
}

.left-nav {
  .MuiButton-label {
    display: flex;
    flex-direction: column;
  }
}
.page-heading {
  font-weight: 300 !important;
  font-size: 36px !important;
  line-height: 54px;
}
.page-heading2 {
  font-weight: 400 !important;
  font-size: 30px !important;
}

.text-white {
  color: #fff;
}

.customForm {
  max-width: 100%;
  margin: 0 auto;
  //padding: 30px;
  //padding-bottom: 20px;
  border-radius: 5px;
  //background-color: #000;
  //border: 1px solid #efecec;
  &.MuiTextField-root {
    margin: 20px 0;
  }
  &h5 {
    margin: 10px 0;
  }
}

.rightButton {
  float: right;
}

.leftButton {
  float: left;
}

.dzu-dropzone {
  overflow: auto !important;
}
.rightPosition {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
}
.notication-list {
  a {
    color: #000;
  }
}
.width120 {
  width: calc(100% - 150px);
}
.ellispsys {
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  white-space: pre;
  width: calc(100% - 30px);
}
.row-reverse {
  flex-direction: row-reverse;
  margin-left: -38px;
}

.orderlist {
  order: 0;
}
@media screen and (max-width: 768px) {
  .orderlist {
    order: 1;
  }
  .row-reverse {
    margin-left: 0px;
    margin-bottom: 20px;
  }
  .activeStep {
    height: 35px;
    width: 35px;
    border: 3px solid #dfdfdf;
  }
  .completed {
    height: 35px;
    width: 35px;
  }

  .step_bar {
    // height: 300px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    box-shadow: 0 0.1rem 0.7rem rgba(0, 0, 0, 0.1);
  }
  .width120 {
    width: 100%;
    margin-bottom: 20px;
  }
  .rightPosition {
    position: absolute;
    top: auto;
    bottom: -5px;

    right: auto;
    left: 55px;
  }
}
.side_nev_Bottom {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.loginForm {
  width: 90%;
  max-width: 600px;
  margin-bottom: 20px;
  backdrop-filter: blur(5px);
  background-color: transparent;
}
.fullwidth {
  width: 100%;
}
.faqBg {
  height: 150px;
  background: url("https://dummyimage.com/600x400/00ab99/00ab99");
  border-radius: 12px;
}
.kycCard {
  border: 1px solid #e46a761f;
  background-color: #fff;
  padding: 15px;
  transition: all 0.3s ease-in-out;
  transform: translateY(0px);
  &:hover {
    transform: translateY(-5px);
    transition: all 0.3s ease-in-out;
  }
}
.boxCard {
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-image: none;
  overflow: hidden;
  border-radius: 16px;
  position: relative;
  z-index: 0;
  text-align: center;
  padding: 40px 0px;
  box-shadow: rgba(90, 114, 123, 0.11) 0px 7px 30px 0px;

  .iconBox {
    margin: auto auto 24px;
    display: flex;
    border-radius: 50%;
    -webkit-box-align: center;
    align-items: center;
    width: 64px;
    height: 64px;
    -webkit-box-pack: center;
    justify-content: center;
  }
}
.greenicon {
  color: rgb(0, 123, 85);
  background-image: linear-gradient(
    135deg,
    rgba(0, 123, 85, 0) 0%,
    rgba(0, 123, 85, 0.24) 100%
  );
}
.green {
  color: rgb(0, 82, 73);
  background-color: rgb(200, 250, 205);
}
.blueicon {
  color: rgb(12, 83, 183);
  background-image: linear-gradient(
    135deg,
    rgba(12, 83, 183, 0) 0%,
    rgba(12, 83, 183, 0.24) 100%
  );
}
.blue {
  color: #dfdfdf;
  background-color: #31335d;
}
.gray {
  background: #b7c3d587;
}
.yellowicon {
  color: rgb(183, 129, 3);
  background-image: linear-gradient(
    135deg,
    rgba(183, 129, 3, 0) 0%,
    rgba(183, 129, 3, 0.24) 100%
  );
}
.yellow {
  color: rgb(122, 79, 1);
  background-color: rgb(255, 247, 205);
}
.redicon {
  color: rgb(183, 33, 54);
  background-image: linear-gradient(
    135deg,
    rgba(183, 33, 54, 0) 0%,
    rgba(183, 33, 54, 0.24) 100%
  );
}
.red {
  color: rgb(122, 12, 46);
  background-color: rgb(255, 231, 217);
}
.mixed-chart2 {
  height: 400px;
}
.userslist {
  background-color: #e0e0e0;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  padding: 7px;
  margin-right: 6px;
  a {
    font-size: 13px;
    font-weight: 600;
    margin-right: 10px;
    text-decoration: none;
  }
}

.bgWhite {
  background-color: #fff;
}

.CircularProgressbar .CircularProgressbar-text {
  fill: red;
  font-size: 20px;
  // dominant-baseline: middle;
  text-anchor: middle;
}

.CircularProgressbar .CircularProgressbar-path {
  stroke: #dfdfdf !important;
  stroke-linecap: round;
  transition: stroke-dashoffset 0.5s ease 0s;
}
.TabButtonsBox1 {
  width: fit-content;
  border-bottom: 1px solid #1eb808;
  /* margin-bottom: 30px; */
  padding-bottom: 10px;
}

.TabButtonsBox1 button.active {
  background-color: #1eb808;
  color: #fff;
}

.TabButtonsBox1 button {
  border: 1px solid #1eb808;
  font-size: 16px;
  font-weight: 600;
  margin-right: 15px;
  padding: 6px 20px;
  border-radius: 4px;
  margin-top: 10px;
}

.TabButtonsBox button.active {
  background-color: initial;
  border-bottom: 2px solid #1eb808;
  border-radius: 0 !important;
  padding: 10px 3px;
  color: #1eb808;
  font-weight: 600 !important;
  font-size: 16px;
  line-height: 22px;
}
.boxLine {
  border-bottom: 0.5px solid #3f3f3f;
}
.TabButtonsBox button {
  font-weight: 600 !important;
  font-size: 16px;
  line-height: 22px;
  border-radius: 5px;
  padding: 10px 3px;
  margin-right: 14px;
  color: #fff;
}

.MuiButton-text {
  padding: 6px 8px;
  font-family: "Saira Semi Condensed", sans-serif;
}

.headingText {
  font-size: 50px;
  color: "White";
  text-align: center;
}

.loginBox {
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
}

.moveTop {
  -webkit-animation: moveTop 5s normal linear infinite;
  animation: moveTop 5s normal linear infinite;
}

@keyframes moveTop {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

.moveLeft {
  -webkit-animation: moveLeft 5s normal linear infinite;
  animation: moveLeft 5s normal linear infinite;
}

@keyframes moveLeft {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  50% {
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px);
  }
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
}
.jodit,
.jodit *,
.jodit-container,
.jodit-container * {
  color: black;
  box-sizing: border-box;
  border: 1px solid White !important;
  border-radius: 5px !important;
}
.jodit-wysiwyg {
  min-height: 220px !important;
}
.jodit-container:not(.jodit_inline) {
  border: 1px solid White !important;
  border-radius: 5px !important;
}
.filterBox {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.deleteText {
  color: #fff;
  text-align: center;
  font-size: 25px !important;
}

::-webkit-scrollbar {
  display: none;
}

.stakeButoon {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.MuiMenu-list {
  background: White;
}
.barcharback {
  path#SvgjsPath1118 {
    fill: #f05440 !important;
  }
}
