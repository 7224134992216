.Component-paper-28 {
  border: 1px solid #3b5c7d;
}
// input:-webkit-autofill {
//     -webkit-background-clip: text !important;
//     -webkit-text-fill-color: transparent !important;
//   }
li.menutext {
  padding-left: 10px;
  a {
    font-size: 14px;
    text-decoration: none;
    color: #707070;
  }
}
select.selectBox {
  border: none !important;
  background: transparent;
  color: #55595e;
  &:focus-visible {
    outline: none;
  }
}

.mainBoxfilter {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
  border: 1px solid #e0e0e0;
  align-items: center;
  justify-content: space-between;
  div {
    padding: 20px;
    border-right: 0.1px solid rgba(224, 224, 224, 1);
    &:last-child {
      border-right: none;
    }
  }
}
.mainBoxfilter4 {
  color: #52565c;
  font-size: 14px;
  width: 22%;
}
.searchBar {
  display: flex;
  align-items: center;
  padding: 0px 20px !important;
  width: 23%;
  svg {
    height: 42px;
    border-left: 0;
    padding: 0 5px;
  }
  input {
    width: 100%;
    padding-left: 10px;
    height: 40px;
    font-size: 11px;
    border: 1px solid #e0e0e0;
    &:focus-visible {
      outline: none;
    }
  }
}

.orderlist {
  background-color: #fff;
}

.blue {
  background-color: #b0c0ed;
  color: #122a6c;
}
.maintable {
  width: 100%;
}

@media (max-width: 1366px) {
  .maintable {
    width: 1050px !important;
  }
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.barcharback {
  foreignObject {
    background: #f5f5f5 !important;
    // transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    // box-shadow: 0px 0px 5px black;
  }
  svg#SvgjsSvg1087 {
    // box-shadow: 0px 0px 3px black;
    // transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
}
// .jodit_toolbar_btn-fullsize{
//     display:none !important;
//     }
